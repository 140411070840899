@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans&display=swap);
html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  /* font-family: 'Poppins', sans-serif; */
  font-family: 'Josefin Sans', sans-serif !important;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Genel Stil Ayarları */
body {
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f8f9fa;
}


/* Ürün Detay Kartı */
.card-detail {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 20px;
    transition: box-shadow 0.3s ease;
    background: #ffffff;
}

.card-detail img {
    width: 100%;
    max-height: 500px;
    object-fit: cover;
    border-radius: 5px 0 0 5px;
}

/* Ürün Bilgi Alanı */
.product-info {
    padding: 20px;
}

.product-info h2 {
    font-size: 2rem;
    font-weight: bold;
    color: #333;
}

.product-info h4 {
    font-size: 1.5rem;
    color: #007bff;
}

.product-info p {
    font-size: 1rem;
    color: #555;
    margin-top: 10px;
}

.text-muted {
    color: #6c757d;
}

/* Satın Alma Butonu */
.btn-form {
    padding: 10px 20px;
    background-color: #d8b18e;
    color: #fff;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
}

.btn-form:hover {
    background-color: #d8b18e;
}

/* Sol ve Sağ Ok Butonlarının Stili */
.btn-prev, .btn-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s ease, border-color 0.3s ease;
}

.btn-prev:hover, .btn-next:hover {
    background-color: #f0f0f0;
    border-color: #ccc;
}

.btn-next {
    right: 12px; /* Sağ ok butonunun konumu */
}


/* Mobil Uyumluluk */
@media only screen and (max-width: 768px) {
    .card-detail img {
        max-height: 300px;
    }

    .product-info h2 {
        font-size: 1.5rem;
    }

    .product-info h4 {
        font-size: 1.2rem;
    }

    .btn-form {
        width: 100%;
        padding: 15px;
    }

    /* Mobilde ok butonlarının boyutunu küçült */
    .btn-prev, .btn-next {
        width: 30px;
        height: 30px;
    }

    .btn-prev:after, .btn-next:after {
        width: 6px;
        height: 6px;
    }

    .btn-prev {
        left: -10px;
    }

    .btn-next {
        right: -10px;
    }
}

.footer-container {
    display: grid; /* Flex yerine grid kullanımı */
    grid-template-columns: repeat(4, 1fr); /* 4 sütun düzeni */
    grid-gap: 15px;
    gap: 15px; /* grid-gap yerine gap kullanımı */
    background-color: rgb(17, 16, 16);
    color: #dcdde1;
    margin: 0 auto; /* Ortalanma sağlanmıştır */
    padding: 60px 15px; /* Üst ve alt padding için */
}

.btn-regular {
    border: none;
    background: #dda06b;
    color: #fff;
    padding: 8px 15px;
    transition: 0.5s;
    position: relative;
    z-index: 99;
}

.btn-regular:before {
    content: '';
    width: 0%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: -10;
    position: absolute;
    transition: 0.3s;
}

.btn-regular:hover:before {
    background-color: rgb(17, 16, 16);
    width: 100%;
}

ul {
    list-style-type: none;
    padding-left: 0; /* Listelerde padding sıfırlanmıştır */
}

li {
    margin: 10px 0;
}

.footer-container .fas {
    color: #dda06b;
    margin-right: 5px;
}

.far,
.fal {
    color: #dda06b;
}

.text-custom {
    color: #dda06b;
}

.bg-custom {
    background-color: #dda06b;
}

.b {
    color: #fff;
}

.footer-copyright {
    text-align: center;
    background-color: #0e0e0ef1;
    color: #dcdde1;
    padding: 5px 0 20px; /* Üst ve alt padding */
}

.footer-icon {
    list-style: none;
    color: #fff;
    font-size: 2em;
    display: flex;
    grid-gap: 10px;
    gap: 10px; /* İkonlar arasında boşluk eklenmiştir */
    padding-left: 0; /* Padding sıfırlanmıştır */
}

.footer-icon li {
    width: 40px;
    height: 40px;
    background-color: #dda06b;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1px;
}

@media only screen and (max-width: 688px) {
    .footer-container {
        grid-template-columns: 1fr; /* Mobil cihazlarda tek sütun düzeni */
    }
}

.map-container {
    position: relative;
    width: 100%; /* Genişliği %100 yaparak tüm genişliği kaplar */
    padding-bottom: 56.25%; /* 16:9 oranı sağlar */
    height: 0;
    overflow: hidden;
}

.map-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}

/* Mobile CSS Styles */
@media (max-width: 768px) {
    /* Arama çubuğunun düzenini değiştir */
    .small-search-box-form-1624553742 {
        width: 100%;
        display: flex;
        justify-content: center;
        margin: 10px 0;
    }

    .small-search-box-form-1624553742 .input {
        width: 70%; /* Giriş kutusunun genişliğini daraltın */
        height: 35px; /* Yüksekliği mobil için daha küçük tutun */
        font-size: 14px; /* Yazı boyutunu küçültün */
    }

    .small-search-box-form-1624553742 .button-search {
        margin-left: 5px;
        padding: 0;
        width: 30px; /* Butonu daha küçük yapın */
    }

    /* Nav ve Navbar düzenlemeleri */
    .navbar {
        padding: 10px; /* Mobilde Navbar'ın üst ve alt boşluklarını daraltın */
    }

    .navbar-brand img {
        width: 100px; /* Logo boyutunu mobilde küçültün */
    }

    .navbar-collapse {
        display: flex;
        flex-direction: column; /* Mobilde dikey düzen */
        align-items: center;
    }

    .nav-link {
        padding: 8px 0; /* Mobilde Nav Link boşluklarını daraltın */
    }

    .nav-link, .btn-regular {
        font-size: 14px; /* Yazı boyutunu mobilde küçültün */
    }
}

.custom-card{
    box-shadow: 0 0 5px rgb(0,0,0,0.5);
    padding: 5px;
    border-radius: 8px;
    margin: 5px;
}
.custom-card img{
    width: 150px;
    height: 150px;
    border-radius: 8px;
}
.detail-info{
    line-height: 13px;
    margin-left: 10px;
}
.text-size{
    font-size: 13px;
}
.mybook-container{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-gap: 10px;
    margin: 20px 0;
}

@media only screen and (max-width:688px){
    .mybook-container{
        grid-template-columns: repeat(1,1fr);
    }
}

.nested-menu{
    text-decoration: none;
    color:black;
    padding:5px 20px;
}
.card-container {
    margin: 10px 10px;
    background-color: #edfaff;
    padding: 5px;
    border-radius: 10px;
    text-align: center; /* Metinlerin ortalanması */
}

.photo img {
    width: 100%;
    height: 320px;
    border-radius: 10px !important;
    transition: 0.3s;
}

.photo {
    overflow: hidden;
}

/* Hover efektini koruyalım */
.card-container:hover img {
    transform: scale(1.1);
}

/* Görselin altına yazıları ekle */
.photo-detail {
    display: flex;
    flex-direction: column; /* Yazıları dikey olarak hizala */
    justify-content: center;
    align-items: stretch;
    width: 100%;
    padding: 10px 5px;
    margin-top: 10px; /* Görselin altına boşluk ekle */
    background-color: #fff; /* Beyaz arka plan */
    border-radius: 0 0 10px 10px; /* Alt köşeleri yuvarla */
    color: rgb(43, 41, 41);
    position: relative; /* Konumlandırmayı sıfırla */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Hafif gölge efekti */
}

/* Butonun görünümünü koruyalım */
.btn-custom {
    text-decoration: none;
    position: relative;
    right: 0; /* Butonu yatayda sıfırla */
    margin-top: 5px; /* Üst boşluk */
    opacity: 0;
    transition: all 0.4s;
}

/* Hover durumunda butonu görünür yap */
.card-container:hover .btn-custom {
    opacity: 1;
    right: 0; /* Buton sağa kaymasın */
}

/* Mobil için Products bileşeni düzenlemeleri */
@media (max-width: 768px) {
    .slick-slider {
        margin: 0 auto;
        width: 100%; /* Slider genişliğini %100 yap */
    }

    .slick-list {
        padding: 0 15px; /* Kenarlarda boşluk bırak */
    }

    .slick-slide {
        display: flex; /* Mobilde her slaytı ortalamak için flex düzen kullan */
        justify-content: center;
        align-items: center;
    }

    .slick-dots {
        bottom: -15px; /* Mobilde noktaların konumunu aşağı kaydır */
    }

    .slick-dots li button:before {
        font-size: 10px; /* Mobilde navigasyon noktalarının boyutunu küçült */
    }

    .slick-prev, .slick-next {
        display: none !important; /* Mobilde okları gizleyin */
    }

    /* Ürün başlık ve açıklama mobil için boyutlandırma */
    .text-center h1 {
        font-size: 18px; /* Başlık boyutunu mobil için küçült */
        margin-bottom: 10px;
    }

    .text-center h2 {
        font-size: 14px; /* Açıklama metin boyutunu küçült */
        line-height: 1.4; /* Satır yüksekliğini küçült */
    }

    /* Ürün kartı düzenlemeleri */
    .product-card {
        padding: 15px; /* Kart içi boşluğu artırın */
        text-align: center; /* Ürün kartı içeriğini ortalayın */
        margin: 10px 5px; /* Mobilde her ürün arasına boşluk ekleyin */
        flex: 1 1 auto; /* Kartların esnek olmasını sağla */
        max-width: 100%; /* Maksimum genişliği %100 yap */
        display: flex; /* Flex düzeni ekleyin */
        flex-direction: column; /* Dikey hizalama */
    }

    .product-card img {
        width: 100%; /* Görsel genişliğini kart genişliğine ayarlayın */
        height: auto; /* Yüksekliği otomatik ayarlayın */
        max-height: 200px; /* Görselin maksimum yüksekliğini sınırlandırın */
        object-fit: cover; /* Görselin kutuya uygun şekilde kesilmesini sağlar */
    }

    .product-card h5 {
        font-size: 14px; /* Ürün adının boyutunu küçültün */
        margin-top: 10px; /* Başlığın üstüne boşluk ekleyin */
    }

    .product-card p {
        font-size: 12px; /* Ürün açıklaması boyutunu küçültün */
    }
}

.banner-container {
    display: flex;
    align-items: center;
    width: 100%;
    height: 80vh;
    background-image: url(/static/media/banner.e0f6650f.jpg);
    background-size: cover;
}

.custom-service {
    margin-top: 60px;
    margin-bottom: 50px;
}

@media only screen and (max-width:688px) {
    .banner-container {
        background-position: center;
    }
}

.font-style {
    font-family:Verdana, Geneva, Tahoma, sans-serif;
    font-size: 2.5rem; /* Font boyutunu isteğinize göre ayarlayabilirsiniz */
    font-weight: normal;
    color: #5c3a2e; /* Yazının rengini kahverengi tonlarında ayarladım */
    letter-spacing: 0.05em; /* Harfler arasındaki mesafeyi biraz açtım */
    line-height: 1.2; /* Satır yüksekliği */
}

.font-style span {
    color: #d4a373; /* 'IŞILTIYI' kelimesinin rengini belirlemek için */
    font-weight: bold; /* 'IŞILTIYI' kelimesini kalın yap */
}

/* Ana düzen */
.product-grid {
    display: flex; /* Flex düzeniyle ürün kartlarını hizalayın */
    flex-wrap: wrap; /* Ürünleri taşan satırlarda aşağıya taşıyın */
    justify-content:center;
}

/* Ürün kartı düzeni */
.product-card {
    width: 300px; /* Varsayılan sabit genişlik */
    height: 400px; /* Varsayılan sabit yükseklik */
    margin-bottom: 2%; /* Dikey boşluk */
    margin-right: 1%; /* Yatay boşluk */
    cursor: pointer;
    overflow: hidden;
    border-radius: 5px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out;
    text-align: center;
    background-color: white;
}

/* Hover durumunda kartın ölçeklenmesi */
.product-card:hover {
    transform: scale(1.05);
}

/* Ürün görseli */
.product-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
    margin-bottom: 2%;
}

/* 700px ve altındaki ekranlar için düzenlemeler */
@media (max-width: 700px) {
    .product-card {
        width: 48%; /* Küçük ekranlar için genişliği %48 yapıyoruz */
        height: 300px; /* Yüksekliği azaltıyoruz */
        margin-bottom: 4%; /* Kartlar arasındaki boşluğu artırıyoruz */
    }

    .product-grid {
        justify-content: space-between; /* Kartları boşluklarla dengeleyin */
        grid-gap: 10px;
        gap: 10px; /* Kartlar arasında boşluk ekleyin */
        padding: 0 10px; /* Kenarlardan boşluk bırakın */
    }
}

/* 450px ve altındaki ekranlar için düzenlemeler */
@media (max-width: 450px) {
    .product-card {
        width: 100%; /* Daha küçük ekranlar için genişliği %100 yapıyoruz */
        height: 250px; /* Yüksekliği daha da azaltıyoruz */
        margin-bottom: 15px; /* Dikey boşluğu artırıyoruz */
        margin-right: 0; /* Yatay boşluğu kaldırıyoruz */
    }

    .product-grid {
        padding: 0 15px; /* Kenarlardan boşluk bırakıyoruz */
    }

    .product-card img {
        width: 100%; /* Kart içindeki görseli genişletiyoruz */
        height: auto;
    }
}

.woman-card,
.man-card,
.kid-card {
    width: 100%;
    height: 190px;
    background: #fde9ed url(/static/media/women-2.26bc0ecf.png);
    background-size: cover;
    padding: 15px;
    border-radius: 5px;
    display: flex;
    align-items: center;
}

.man-card {
    width: 100%;
    height: 190px;
    background: #e9f6f4 url(/static/media/men-2.0c8ff69a.png);
    background-size: cover;
}

.kid-card {
    width: 100%;
    height: 190px;
    background: #f9f0ea url(/static/media/kids-2.b7b1949e.png);
    background-size: cover;
}

.btn-service {
    border: none;
    background: #2b3144;
    color: #fff;
    padding: 8px 15px;
    border-radius: 3px;
}
.deal-card,
.fashion-card {
    width: 100%;
    height: 300px;
    padding: 30px;
    border-radius: 5px;
    background-size: cover;
    display: flex;
    align-items: center;
    opacity: 0.9;
}

.deal-card {
    background: url(/static/media/sw1.84288a50.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.fashion-card {
    background: url(/static/media/sw2.ee9727e2.png);
    background-size: contain;
    background-repeat: no-repeat;
}
.info-container {
    width: 100%;
    height: 80vh;
    background: url('https://wpbingosite.com/wordpress/mojuri/wp-content/uploads/2021/08/slider-10-8.jpg');
    background-attachment: fixed;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 100px;
    color: rgb(114, 112, 112);
}
/* Genel Stil */
.jewellery-container {
    padding: 40px 20px;
    max-width: 95%;
    margin: auto;
}

.row{
    justify-content: center;
}

.col-md-9 {
    flex: 0 0 auto;
    width: 85%;
}

/* Jewellery Row */
.jewellery-row {
    flex-direction: row; /* Ekran boyutuna göre yönlendirme */
    flex-wrap: wrap; /* Taşan öğelerin alt satıra geçmesini sağla */
    justify-content: center; /* Ortala */
    grid-gap: 10px;
    gap: 10px; /* Boşluk ayarlama */
}


/* Jewellery Card */
.card-container {
    display: flex;
    flex-direction: column; /* Kart içeriğini dikey olarak hizala */
    justify-content: flex-start;
    align-items: center;
    border: none;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    /*overflow: hidden;  Kart sınırlarının dışına çıkan öğeleri gizle */
    /*width: 100%;  Kart genişliği, responsive için ayarlanmış */
    max-width: 250px; /* Kart genişliği sınırı */
    padding: 0; /* Kart içeriği için boşluk sıfırlandı */
    margin-bottom: 20px; /* Alt boşluk */
}

.card-container:hover {
    transform: translateY(-5px); /* Hover'da yukarı kalkma efekti */
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2); /* Hover efektinde gölgeyi derinleştir */
}

/* Jewellery Card Image */
.photo {
    width: 100%; /* Görsel genişliği kart genişliği ile aynı */
    height: auto; /* Yükseklik oranı otomatik */
    border-bottom: 1px solid #ddd; /* Alt sınır */
    overflow: hidden;
}

.photo img {
    object-fit: cover; /* Görselin taşmasını engelle */
    height: 320px; /* Görsel yüksekliği */
    border-bottom: 1px solid #ddd; /* Alt sınır */
    object-fit: cover; /* Görselin kutuya sığmasını sağla */
    aspect-ratio: 4/3; /* En-boy oranını 4:3 olarak belirle */
}

/* Jewellery Card Title */
.text-muted {
    font-size: 1.1rem; /* Başlık boyutu */
    font-weight: bold;
    color: #333;
    padding: 10px 15px; /* İç boşluk */
    margin-bottom: 0px; /* Alt boşluk sıfır/////////// */
    width: 100%; /* Başlık genişliği kart ile aynı */
    text-align: center; /* Ortala */
    background-color: #f8f8f8; /* Başlık arka plan rengi */
    border-top: 1px solid #ddd; /* Üst sınır */
    transition: color 0.2s ease; /* Renk geçişi */
    border-radius: 8px;
}

.text-muted:hover {
    color: #ff4c4c; /* Hover durumunda başlığı vurgula */
}

/* Filter Menu */
.col-xl-3 {
    flex: 0 0 auto;
    width: 15%;
}

.filter-menu {
    padding: 20px; /* İçerik boşluğu */
    border-radius: 12px; /* Köşeleri yuvarla */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Hafif gölge efekti */
    background-color: #f8f9fa; /* Arka plan rengi */
    transition: all 0.3s ease; /* Geçiş efekti */
}

.form-check-input:checked {
    background-color: #dda06b;
    border-color: #dda06b;
}

.filter-menu:hover {
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2); /* Hover durumunda derinleşme efekti */
}

/* Filter Menu Title */
.filter-title {
    font-size: 1.6rem; /* Başlık boyutu */
    font-weight: bold;
    margin-bottom: 20px;
    color: #444; /* Başlık rengi */
}

/* Filter Category Styles */
.filter-category {
    margin-bottom: 20px; /* Kategoriler arası boşluk */
    display: flex;
    flex-direction: column; /* İç öğeleri dikey olarak hizala */
    align-items: flex-start; /* Öğeleri sola hizala */
}

.category-title {
    font-size: 1.25rem; /* Kategori başlığı boyutu */
    font-weight: bold;
    margin-bottom: 10px; /* Alt boşluk */
    padding-bottom: 5px;
    border-bottom: 1px solid #eee; /* Alt çizgi */
    color: #555; /* Başlık rengi */
}

/* Filter Checkbox Styling */
.filter-checkbox {
    margin-bottom: 10px; /* Checkbox'lar arası boşluk */
    cursor: pointer; /* Tıklanabilir imleç */
}

.filter-checkbox label {
    color: #333; /* Checkbox etiket rengi */
    transition: color 0.2s ease; /* Renk geçişi */
}

.filter-checkbox label:hover {
    color: #ff4c4c; /* Hover durumunda renk değişimi */
}

/* Checkbox Hover Effect */
.filter-checkbox input[type="checkbox"]:hover {
    transform: scale(1.15); /* Hover durumunda büyütme efekti */
}

/* Scrollbar Styling */
.filter-menu::-webkit-scrollbar {
    width: 8px; /* Kaydırma çubuğu genişliği */
}

.filter-menu::-webkit-scrollbar-track {
    background: #f2f2f2; /* Kaydırma çubuğu yolu */
}

.filter-menu::-webkit-scrollbar-thumb {
    background-color: #888; /* Kaydırma çubuğu rengi */
    border-radius: 10px;
}

.filter-menu::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Hover durumunda kaydırma rengi */
}


/* Responsive Styles */
@media (max-width: 992px) {
    /* Tablet ve daha küçük ekranlar için 3 kolon düzeni */
    .filter-menu {
        max-width: 100%; /* Maksimum genişlik 100% olmalı */
        margin-bottom: 30px; /* Alt boşluk */
        padding: 15px; /* İçerik boşluğu */
        position: relative; /* Konumlandırma ayarları */
    }
    .jewellery-row {
        grid-gap: 10px;
        gap: 10px;
        justify-content: space-around;
    }

    .jewellery-card {
        max-width: 30%; /* Kart genişliğini ayarla */
    }

    .filter-menu {
        max-width: 100%;
        margin-bottom: 30px;
        padding: 15px;
    }
}

@media (max-width: 768px) {
    .jewellery-row {
        flex-direction: column; /* Dikey düzen */
        align-items: center; /* Ortala */
        grid-gap: 20px;
        gap: 20px; /* Aralıklar */
    }

    .col-xl-3 {
        width: 100%; /* Filtre menüsünün tam genişlik almasını sağla */
        margin-bottom: 20px; /* Alt boşluk */
    }

    .filter-menu {
        position: relative; /* Konumlandırmayı ayarla */
        width: 100%; /* Filtre menüsünün tam genişliğini ayarla */
    }
}

@media (max-width: 576px) {
    .jewellery-row {
        justify-content: center; /* Kartları ortala */
    }

    .jewellery-card {
        max-width: 100%; /* Kart genişliği */
    }

    .filter-menu {
        width: 100%; /* Tam genişlik */
    }
}

.btn-form {
    border: none;
    border-radius: 2px;
    background: #dda06b;
    color: #fff;
    font-weight: bold;
}
.popup {
    position: absolute;
    background-color: white;
    border: 1px solid #ccc;
    padding: 10px;
    z-index: 10;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    margin-top: -300px; /* Alanın hemen üstünde görünmesi için */
}

.popup h5 {
    margin: 0 0 5px;
}

.popup ul {
    margin: 0;
    padding-left: 20px;
}

.popup li {
    font-size: 14px;
}

