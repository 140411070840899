.banner-container {
    display: flex;
    align-items: center;
    width: 100%;
    height: 80vh;
    background-image: url('../../../images/banner.jpg');
    background-size: cover;
}

.custom-service {
    margin-top: 60px;
    margin-bottom: 50px;
}

@media only screen and (max-width:688px) {
    .banner-container {
        background-position: center;
    }
}

.font-style {
    font-family:Verdana, Geneva, Tahoma, sans-serif;
    font-size: 2.5rem; /* Font boyutunu isteğinize göre ayarlayabilirsiniz */
    font-weight: normal;
    color: #5c3a2e; /* Yazının rengini kahverengi tonlarında ayarladım */
    letter-spacing: 0.05em; /* Harfler arasındaki mesafeyi biraz açtım */
    line-height: 1.2; /* Satır yüksekliği */
}

.font-style span {
    color: #d4a373; /* 'IŞILTIYI' kelimesinin rengini belirlemek için */
    font-weight: bold; /* 'IŞILTIYI' kelimesini kalın yap */
}
