/* Mobile CSS Styles */
@media (max-width: 768px) {
    /* Arama çubuğunun düzenini değiştir */
    .small-search-box-form-1624553742 {
        width: 100%;
        display: flex;
        justify-content: center;
        margin: 10px 0;
    }

    .small-search-box-form-1624553742 .input {
        width: 70%; /* Giriş kutusunun genişliğini daraltın */
        height: 35px; /* Yüksekliği mobil için daha küçük tutun */
        font-size: 14px; /* Yazı boyutunu küçültün */
    }

    .small-search-box-form-1624553742 .button-search {
        margin-left: 5px;
        padding: 0;
        width: 30px; /* Butonu daha küçük yapın */
    }

    /* Nav ve Navbar düzenlemeleri */
    .navbar {
        padding: 10px; /* Mobilde Navbar'ın üst ve alt boşluklarını daraltın */
    }

    .navbar-brand img {
        width: 100px; /* Logo boyutunu mobilde küçültün */
    }

    .navbar-collapse {
        display: flex;
        flex-direction: column; /* Mobilde dikey düzen */
        align-items: center;
    }

    .nav-link {
        padding: 8px 0; /* Mobilde Nav Link boşluklarını daraltın */
    }

    .nav-link, .btn-regular {
        font-size: 14px; /* Yazı boyutunu mobilde küçültün */
    }
}
