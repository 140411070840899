.custom-card{
    box-shadow: 0 0 5px rgb(0,0,0,0.5);
    padding: 5px;
    border-radius: 8px;
    margin: 5px;
}
.custom-card img{
    width: 150px;
    height: 150px;
    border-radius: 8px;
}
.detail-info{
    line-height: 13px;
    margin-left: 10px;
}
.text-size{
    font-size: 13px;
}