.deal-card,
.fashion-card {
    width: 100%;
    height: 300px;
    padding: 30px;
    border-radius: 5px;
    background-size: cover;
    display: flex;
    align-items: center;
    opacity: 0.9;
}

.deal-card {
    background: url('../../../images/sw1.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.fashion-card {
    background: url('../../../images/sw2.png');
    background-size: contain;
    background-repeat: no-repeat;
}