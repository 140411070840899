/* Ana düzen */
.product-grid {
    display: flex; /* Flex düzeniyle ürün kartlarını hizalayın */
    flex-wrap: wrap; /* Ürünleri taşan satırlarda aşağıya taşıyın */
    justify-content:center;
}

/* Ürün kartı düzeni */
.product-card {
    width: 300px; /* Varsayılan sabit genişlik */
    height: 400px; /* Varsayılan sabit yükseklik */
    margin-bottom: 2%; /* Dikey boşluk */
    margin-right: 1%; /* Yatay boşluk */
    cursor: pointer;
    overflow: hidden;
    border-radius: 5px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out;
    text-align: center;
    background-color: white;
}

/* Hover durumunda kartın ölçeklenmesi */
.product-card:hover {
    transform: scale(1.05);
}

/* Ürün görseli */
.product-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
    margin-bottom: 2%;
}

/* 700px ve altındaki ekranlar için düzenlemeler */
@media (max-width: 700px) {
    .product-card {
        width: 48%; /* Küçük ekranlar için genişliği %48 yapıyoruz */
        height: 300px; /* Yüksekliği azaltıyoruz */
        margin-bottom: 4%; /* Kartlar arasındaki boşluğu artırıyoruz */
    }

    .product-grid {
        justify-content: space-between; /* Kartları boşluklarla dengeleyin */
        gap: 10px; /* Kartlar arasında boşluk ekleyin */
        padding: 0 10px; /* Kenarlardan boşluk bırakın */
    }
}

/* 450px ve altındaki ekranlar için düzenlemeler */
@media (max-width: 450px) {
    .product-card {
        width: 100%; /* Daha küçük ekranlar için genişliği %100 yapıyoruz */
        height: 250px; /* Yüksekliği daha da azaltıyoruz */
        margin-bottom: 15px; /* Dikey boşluğu artırıyoruz */
        margin-right: 0; /* Yatay boşluğu kaldırıyoruz */
    }

    .product-grid {
        padding: 0 15px; /* Kenarlardan boşluk bırakıyoruz */
    }

    .product-card img {
        width: 100%; /* Kart içindeki görseli genişletiyoruz */
        height: auto;
    }
}
