.card-container {
    margin: 10px 10px;
    background-color: #edfaff;
    padding: 5px;
    border-radius: 10px;
    text-align: center; /* Metinlerin ortalanması */
}

.photo img {
    width: 100%;
    height: 320px;
    border-radius: 10px !important;
    transition: 0.3s;
}

.photo {
    overflow: hidden;
}

/* Hover efektini koruyalım */
.card-container:hover img {
    transform: scale(1.1);
}

/* Görselin altına yazıları ekle */
.photo-detail {
    display: flex;
    flex-direction: column; /* Yazıları dikey olarak hizala */
    justify-content: center;
    align-items: stretch;
    width: 100%;
    padding: 10px 5px;
    margin-top: 10px; /* Görselin altına boşluk ekle */
    background-color: #fff; /* Beyaz arka plan */
    border-radius: 0 0 10px 10px; /* Alt köşeleri yuvarla */
    color: rgb(43, 41, 41);
    position: relative; /* Konumlandırmayı sıfırla */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Hafif gölge efekti */
}

/* Butonun görünümünü koruyalım */
.btn-custom {
    text-decoration: none;
    position: relative;
    right: 0; /* Butonu yatayda sıfırla */
    margin-top: 5px; /* Üst boşluk */
    opacity: 0;
    transition: all 0.4s;
}

/* Hover durumunda butonu görünür yap */
.card-container:hover .btn-custom {
    opacity: 1;
    right: 0; /* Buton sağa kaymasın */
}
