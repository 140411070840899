/* Mobil için Products bileşeni düzenlemeleri */
@media (max-width: 768px) {
    .slick-slider {
        margin: 0 auto;
        width: 100%; /* Slider genişliğini %100 yap */
    }

    .slick-list {
        padding: 0 15px; /* Kenarlarda boşluk bırak */
    }

    .slick-slide {
        display: flex; /* Mobilde her slaytı ortalamak için flex düzen kullan */
        justify-content: center;
        align-items: center;
    }

    .slick-dots {
        bottom: -15px; /* Mobilde noktaların konumunu aşağı kaydır */
    }

    .slick-dots li button:before {
        font-size: 10px; /* Mobilde navigasyon noktalarının boyutunu küçült */
    }

    .slick-prev, .slick-next {
        display: none !important; /* Mobilde okları gizleyin */
    }

    /* Ürün başlık ve açıklama mobil için boyutlandırma */
    .text-center h1 {
        font-size: 18px; /* Başlık boyutunu mobil için küçült */
        margin-bottom: 10px;
    }

    .text-center h2 {
        font-size: 14px; /* Açıklama metin boyutunu küçült */
        line-height: 1.4; /* Satır yüksekliğini küçült */
    }

    /* Ürün kartı düzenlemeleri */
    .product-card {
        padding: 15px; /* Kart içi boşluğu artırın */
        text-align: center; /* Ürün kartı içeriğini ortalayın */
        margin: 10px 5px; /* Mobilde her ürün arasına boşluk ekleyin */
        flex: 1 1 auto; /* Kartların esnek olmasını sağla */
        max-width: 100%; /* Maksimum genişliği %100 yap */
        display: flex; /* Flex düzeni ekleyin */
        flex-direction: column; /* Dikey hizalama */
    }

    .product-card img {
        width: 100%; /* Görsel genişliğini kart genişliğine ayarlayın */
        height: auto; /* Yüksekliği otomatik ayarlayın */
        max-height: 200px; /* Görselin maksimum yüksekliğini sınırlandırın */
        object-fit: cover; /* Görselin kutuya uygun şekilde kesilmesini sağlar */
    }

    .product-card h5 {
        font-size: 14px; /* Ürün adının boyutunu küçültün */
        margin-top: 10px; /* Başlığın üstüne boşluk ekleyin */
    }

    .product-card p {
        font-size: 12px; /* Ürün açıklaması boyutunu küçültün */
    }
}
