@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans&display=swap');

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  /* font-family: 'Poppins', sans-serif; */
  font-family: 'Josefin Sans', sans-serif !important;
}