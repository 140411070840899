/* Genel Stil Ayarları */
body {
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f8f9fa;
}


/* Ürün Detay Kartı */
.card-detail {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 20px;
    transition: box-shadow 0.3s ease;
    background: #ffffff;
}

.card-detail img {
    width: 100%;
    max-height: 500px;
    object-fit: cover;
    border-radius: 5px 0 0 5px;
}

/* Ürün Bilgi Alanı */
.product-info {
    padding: 20px;
}

.product-info h2 {
    font-size: 2rem;
    font-weight: bold;
    color: #333;
}

.product-info h4 {
    font-size: 1.5rem;
    color: #007bff;
}

.product-info p {
    font-size: 1rem;
    color: #555;
    margin-top: 10px;
}

.text-muted {
    color: #6c757d;
}

/* Satın Alma Butonu */
.btn-form {
    padding: 10px 20px;
    background-color: #d8b18e;
    color: #fff;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
}

.btn-form:hover {
    background-color: #d8b18e;
}

/* Sol ve Sağ Ok Butonlarının Stili */
.btn-prev, .btn-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s ease, border-color 0.3s ease;
}

.btn-prev:hover, .btn-next:hover {
    background-color: #f0f0f0;
    border-color: #ccc;
}

.btn-next {
    right: 12px; /* Sağ ok butonunun konumu */
}


/* Mobil Uyumluluk */
@media only screen and (max-width: 768px) {
    .card-detail img {
        max-height: 300px;
    }

    .product-info h2 {
        font-size: 1.5rem;
    }

    .product-info h4 {
        font-size: 1.2rem;
    }

    .btn-form {
        width: 100%;
        padding: 15px;
    }

    /* Mobilde ok butonlarının boyutunu küçült */
    .btn-prev, .btn-next {
        width: 30px;
        height: 30px;
    }

    .btn-prev:after, .btn-next:after {
        width: 6px;
        height: 6px;
    }

    .btn-prev {
        left: -10px;
    }

    .btn-next {
        right: -10px;
    }
}
