/* Genel Stil */
.jewellery-container {
    padding: 40px 20px;
    max-width: 95%;
    margin: auto;
}

.row{
    justify-content: center;
}

.col-md-9 {
    flex: 0 0 auto;
    width: 85%;
}

/* Jewellery Row */
.jewellery-row {
    flex-direction: row; /* Ekran boyutuna göre yönlendirme */
    flex-wrap: wrap; /* Taşan öğelerin alt satıra geçmesini sağla */
    justify-content: center; /* Ortala */
    gap: 10px; /* Boşluk ayarlama */
}


/* Jewellery Card */
.card-container {
    display: flex;
    flex-direction: column; /* Kart içeriğini dikey olarak hizala */
    justify-content: flex-start;
    align-items: center;
    border: none;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    /*overflow: hidden;  Kart sınırlarının dışına çıkan öğeleri gizle */
    /*width: 100%;  Kart genişliği, responsive için ayarlanmış */
    max-width: 250px; /* Kart genişliği sınırı */
    padding: 0; /* Kart içeriği için boşluk sıfırlandı */
    margin-bottom: 20px; /* Alt boşluk */
}

.card-container:hover {
    transform: translateY(-5px); /* Hover'da yukarı kalkma efekti */
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2); /* Hover efektinde gölgeyi derinleştir */
}

/* Jewellery Card Image */
.photo {
    width: 100%; /* Görsel genişliği kart genişliği ile aynı */
    height: auto; /* Yükseklik oranı otomatik */
    border-bottom: 1px solid #ddd; /* Alt sınır */
    overflow: hidden;
}

.photo img {
    object-fit: cover; /* Görselin taşmasını engelle */
    height: 320px; /* Görsel yüksekliği */
    border-bottom: 1px solid #ddd; /* Alt sınır */
    object-fit: cover; /* Görselin kutuya sığmasını sağla */
    aspect-ratio: 4/3; /* En-boy oranını 4:3 olarak belirle */
}

/* Jewellery Card Title */
.text-muted {
    font-size: 1.1rem; /* Başlık boyutu */
    font-weight: bold;
    color: #333;
    padding: 10px 15px; /* İç boşluk */
    margin-bottom: 0px; /* Alt boşluk sıfır/////////// */
    width: 100%; /* Başlık genişliği kart ile aynı */
    text-align: center; /* Ortala */
    background-color: #f8f8f8; /* Başlık arka plan rengi */
    border-top: 1px solid #ddd; /* Üst sınır */
    transition: color 0.2s ease; /* Renk geçişi */
    border-radius: 8px;
}

.text-muted:hover {
    color: #ff4c4c; /* Hover durumunda başlığı vurgula */
}

/* Filter Menu */
.col-xl-3 {
    flex: 0 0 auto;
    width: 15%;
}

.filter-menu {
    padding: 20px; /* İçerik boşluğu */
    border-radius: 12px; /* Köşeleri yuvarla */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Hafif gölge efekti */
    background-color: #f8f9fa; /* Arka plan rengi */
    transition: all 0.3s ease; /* Geçiş efekti */
}

.form-check-input:checked {
    background-color: #dda06b;
    border-color: #dda06b;
}

.filter-menu:hover {
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2); /* Hover durumunda derinleşme efekti */
}

/* Filter Menu Title */
.filter-title {
    font-size: 1.6rem; /* Başlık boyutu */
    font-weight: bold;
    margin-bottom: 20px;
    color: #444; /* Başlık rengi */
}

/* Filter Category Styles */
.filter-category {
    margin-bottom: 20px; /* Kategoriler arası boşluk */
    display: flex;
    flex-direction: column; /* İç öğeleri dikey olarak hizala */
    align-items: flex-start; /* Öğeleri sola hizala */
}

.category-title {
    font-size: 1.25rem; /* Kategori başlığı boyutu */
    font-weight: bold;
    margin-bottom: 10px; /* Alt boşluk */
    padding-bottom: 5px;
    border-bottom: 1px solid #eee; /* Alt çizgi */
    color: #555; /* Başlık rengi */
}

/* Filter Checkbox Styling */
.filter-checkbox {
    margin-bottom: 10px; /* Checkbox'lar arası boşluk */
    cursor: pointer; /* Tıklanabilir imleç */
}

.filter-checkbox label {
    color: #333; /* Checkbox etiket rengi */
    transition: color 0.2s ease; /* Renk geçişi */
}

.filter-checkbox label:hover {
    color: #ff4c4c; /* Hover durumunda renk değişimi */
}

/* Checkbox Hover Effect */
.filter-checkbox input[type="checkbox"]:hover {
    transform: scale(1.15); /* Hover durumunda büyütme efekti */
}

/* Scrollbar Styling */
.filter-menu::-webkit-scrollbar {
    width: 8px; /* Kaydırma çubuğu genişliği */
}

.filter-menu::-webkit-scrollbar-track {
    background: #f2f2f2; /* Kaydırma çubuğu yolu */
}

.filter-menu::-webkit-scrollbar-thumb {
    background-color: #888; /* Kaydırma çubuğu rengi */
    border-radius: 10px;
}

.filter-menu::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Hover durumunda kaydırma rengi */
}


/* Responsive Styles */
@media (max-width: 992px) {
    /* Tablet ve daha küçük ekranlar için 3 kolon düzeni */
    .filter-menu {
        max-width: 100%; /* Maksimum genişlik 100% olmalı */
        margin-bottom: 30px; /* Alt boşluk */
        padding: 15px; /* İçerik boşluğu */
        position: relative; /* Konumlandırma ayarları */
    }
    .jewellery-row {
        gap: 10px;
        justify-content: space-around;
    }

    .jewellery-card {
        max-width: 30%; /* Kart genişliğini ayarla */
    }

    .filter-menu {
        max-width: 100%;
        margin-bottom: 30px;
        padding: 15px;
    }
}

@media (max-width: 768px) {
    .jewellery-row {
        flex-direction: column; /* Dikey düzen */
        align-items: center; /* Ortala */
        gap: 20px; /* Aralıklar */
    }

    .col-xl-3 {
        width: 100%; /* Filtre menüsünün tam genişlik almasını sağla */
        margin-bottom: 20px; /* Alt boşluk */
    }

    .filter-menu {
        position: relative; /* Konumlandırmayı ayarla */
        width: 100%; /* Filtre menüsünün tam genişliğini ayarla */
    }
}

@media (max-width: 576px) {
    .jewellery-row {
        justify-content: center; /* Kartları ortala */
    }

    .jewellery-card {
        max-width: 100%; /* Kart genişliği */
    }

    .filter-menu {
        width: 100%; /* Tam genişlik */
    }
}
