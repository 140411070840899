.popup {
    position: absolute;
    background-color: white;
    border: 1px solid #ccc;
    padding: 10px;
    z-index: 10;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    margin-top: -300px; /* Alanın hemen üstünde görünmesi için */
}

.popup h5 {
    margin: 0 0 5px;
}

.popup ul {
    margin: 0;
    padding-left: 20px;
}

.popup li {
    font-size: 14px;
}
