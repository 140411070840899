.footer-container {
    display: grid; /* Flex yerine grid kullanımı */
    grid-template-columns: repeat(4, 1fr); /* 4 sütun düzeni */
    gap: 15px; /* grid-gap yerine gap kullanımı */
    background-color: rgb(17, 16, 16);
    color: #dcdde1;
    margin: 0 auto; /* Ortalanma sağlanmıştır */
    padding: 60px 15px; /* Üst ve alt padding için */
}

.btn-regular {
    border: none;
    background: #dda06b;
    color: #fff;
    padding: 8px 15px;
    transition: 0.5s;
    position: relative;
    z-index: 99;
}

.btn-regular:before {
    content: '';
    width: 0%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: -10;
    position: absolute;
    transition: 0.3s;
}

.btn-regular:hover:before {
    background-color: rgb(17, 16, 16);
    width: 100%;
}

ul {
    list-style-type: none;
    padding-left: 0; /* Listelerde padding sıfırlanmıştır */
}

li {
    margin: 10px 0;
}

.footer-container .fas {
    color: #dda06b;
    margin-right: 5px;
}

.far,
.fal {
    color: #dda06b;
}

.text-custom {
    color: #dda06b;
}

.bg-custom {
    background-color: #dda06b;
}

.b {
    color: #fff;
}

.footer-copyright {
    text-align: center;
    background-color: #0e0e0ef1;
    color: #dcdde1;
    padding: 5px 0 20px; /* Üst ve alt padding */
}

.footer-icon {
    list-style: none;
    color: #fff;
    font-size: 2em;
    display: flex;
    gap: 10px; /* İkonlar arasında boşluk eklenmiştir */
    padding-left: 0; /* Padding sıfırlanmıştır */
}

.footer-icon li {
    width: 40px;
    height: 40px;
    background-color: #dda06b;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1px;
}

@media only screen and (max-width: 688px) {
    .footer-container {
        grid-template-columns: 1fr; /* Mobil cihazlarda tek sütun düzeni */
    }
}

.map-container {
    position: relative;
    width: 100%; /* Genişliği %100 yaparak tüm genişliği kaplar */
    padding-bottom: 56.25%; /* 16:9 oranı sağlar */
    height: 0;
    overflow: hidden;
}

.map-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}
